import { useEffect } from "react";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { appInsights } from "../../config/appInsights";

import { TCustomerApproverDetails } from "../../custom-hooks/usePendingSignatoryUsers";

import styles from "./style.module.scss";
import { formatMoney } from "accounting";

interface IDetails {
  data: {
    approverDetails: TCustomerApproverDetails[];
    rolesRequestedForApproval: string[];
    requestedApprovedAmount: number;
  };
}

const UnlockApproverDetails = ({
  data: { approverDetails, rolesRequestedForApproval, requestedApprovedAmount },
}: IDetails) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Details.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div
      className={`table-view speed-1x animated fadeInRight ${styles.DetailsContainer}`}
    >
      <h1>Approver Details</h1>
      <div>
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Customer Type</th>
                <th>Phone Number</th>
                <th>Status</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {approverDetails &&
                approverDetails.map((data, _id: number) => {
                  const {
                    email,
                    firstName,
                    lastName,
                    funderPersonType,
                    phoneNumber,
                    status,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{email}</td>
                      <td>{funderPersonType}</td>
                      <td>{phoneNumber}</td>
                      <td>{status}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      </div>

      {/* <div className={styles.RolesRequestedContainer}>
        <h1>Roles Requested For Approval</h1>
        <div>
          <ul>
            {rolesRequestedForApproval.map((_item) => (
              <li key={_item}>
                <p>{_item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div> */}

      {/* <div className={styles.RolesRequestedContainer}>
        <h1>Requested Approved Amount</h1>

        <ul>
          <li>
            <p>{formatMoney(requestedApprovedAmount, "NGN")}</p>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default UnlockApproverDetails;
