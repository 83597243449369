import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IPaginatedResponse } from "../interfaces/response";

export interface IPendingApprovalParams {
  page: number;
  pageSize: number;
  email?: string;
  phoneNumber?: string;
  bvn?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  name?: string;
}

export type TCustomerApproverDetails = {
  funderPeopleId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  funderPersonType: string;
  status: string;
};

export interface IPendingSignatorySetupApproval {
  id: number;
  customerId: number;
  businessName: string;
  email: string;
  numberOfSignatories: number;
  referenceNumber: string;
  createdAt: string;
}

export const getPendingSignatorySetup = async ({
  page,
  pageSize,
  startDate,
  endDate,
  bvn,
  email,
  name,
  phoneNumber,
  status,
}: IPendingApprovalParams): Promise<
  IPaginatedResponse<IPendingSignatorySetupApproval[]>
> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          page: 1,
          pageSize: 10,
          totalCount: 100,
          totalPages: 100,
          data: [
            {
              id: 1,
              customerId: 1517,
              businessName: "John Doe Inc",
              email: "marsh@yopmail.com",
              numberOfSignatories: 1,
              referenceNumber: "102751e6-0cf8-405d-9070-7d4ee3f55108",
              createdAt: "2025-01-06T16:44:41.359061",
            },
          ],
        });
      }, 5000);
    });
  }

  let queryParams = `?Page=${page}&PageSize=${pageSize}`;
  if (startDate) queryParams += `&startDate=${startDate}`;
  if (endDate) queryParams += `&endDate=${endDate}`;
  if (email) queryParams += `&email=${email}`;
  if (bvn) queryParams += `&bvn=${bvn}`;
  if (name) queryParams += `&name=${name}`;
  if (phoneNumber) queryParams += `&phoneNumber=${phoneNumber}`;
  if (status) queryParams += `&status=${status}`;

  const { data } = await getData(
    `${ajaxEndpoints.DB_GET_PENDING_SIGNATORY_SETUP}${queryParams}`
  );

  return data;
};

export const usePendingSigntorySetup = (params: IPendingApprovalParams) => {
  return useQuery<IPaginatedResponse<IPendingSignatorySetupApproval[]>, Error>(
    ["getPendingSignatorySetup", params],
    () => getPendingSignatorySetup(params),
    {
      refetchOnWindowFocus: false,
    }
  );
};
